<template>
  <div>
    <a v-if="isMobile" class="has-text-black" :href="notificationsPath()">
      <IconWithBadge role="button" :count="unreadCount">
        <span class="tw-iconify tw-bi--bell" :class="badgeClasses" />
      </IconWithBadge>
    </a>
    <VDropdown v-else data-test-selector="the-notification" paddingless @show="handleShow">
      <IconWithBadge role="button" :count="unreadCount">
        <span class="tw-iconify tw-bi--bell" :class="badgeClasses" />
      </IconWithBadge>
      <template #popper>
        <NotificationContainer :is-loading="loading" :is-empty="isEmpty" data-test-selector="the-notification-content">
          <template #header>
            <a :href="notificationsPath()"> すべて見る </a>
          </template>
          <NotificationItem
            v-for="notificationRecipient in notificationRecipients"
            :key="notificationRecipient.notification.id"
            :notification-recipient="notificationRecipient"
          />
          <template #empty> まだ通知はありません。 </template>
        </NotificationContainer>
      </template>
    </VDropdown>
  </div>
</template>

<script lang="ts">
import type { UseQueryReturn } from '@vue/apollo-composable'
import { provideApolloClient, useQuery } from '@vue/apollo-composable'
import type { Ref } from 'vue'
import type { NotificationRecipientsQuery, NotificationRecipientsQueryVariables } from '@/src/graphql/operations'
import { NotificationRecipientsDocument } from '@/src/graphql/operations'
import type { RelayNode } from '@/src/graphql/typeUtil'
import { useEmpty, useMobileScreen } from '@/src/hooks'
import { useBoolean } from '@/src/hooks/useBoolean'
import { useConnectionResult } from '@/src/hooks/useConnectionResult'
import { getApolloClient } from '@/src/lib/apollo'
import { notificationsPath } from '@/src/lib/rails-routes'

type NotificationRecipientsQueryNode = RelayNode<NotificationRecipientsQuery['notificationRecipients']>

type UseNotificationRecipientsResult = {
  result: Ref<readonly NotificationRecipientsQueryNode[]>
  loading: Ref<boolean>
  refetch: UseQueryReturn<NotificationRecipientsQuery, NotificationRecipientsQueryVariables>['refetch']
}

const useNotificationRecipients = (enabled: Ref<boolean>): UseNotificationRecipientsResult => {
  const { result, loading, refetch } = useQuery(
    NotificationRecipientsDocument,
    {
      onlyUnread: false,
      first: 5,
    },
    () => ({
      // NOTE: 初回クリック後にデータロードするように
      enabled: enabled.value,
    }),
  )

  const connection = computed(() => result?.value?.notificationRecipients)
  const { nodes } = useConnectionResult(connection)
  return { result: nodes, loading, refetch }
}

export default defineComponent({
  props: {
    unreadCount: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    provideApolloClient(getApolloClient())

    const [enabled, { on: enable }] = useBoolean(false)
    const { result, loading, refetch } = useNotificationRecipients(enabled)
    const isMobile = useMobileScreen()

    const badgeClasses = computed(() => (props.unreadCount > 0 ? 'tw-animate-pulse' : undefined))

    const isEmpty = useEmpty(result)

    const handleShow = (): void => {
      enable()
      refetch()
    }

    return {
      badgeClasses,
      loading,
      isEmpty,
      isMobile,
      handleShow,
      notificationsPath,
      notificationRecipients: result,
    }
  },
})
</script>
