import type { ApolloLink } from '@apollo/client/core'
import type { ErrorResponse } from '@apollo/client/link/error'
import { onError } from '@apollo/client/link/error'
import { debounce } from 'lodash-es'
import { showError } from './toast'

// eslint-disable-next-line import/order
import type { GraphQLFormattedError } from 'graphql'

const ERROR_MESSAGES = {
  INVALID_AUTHENTICITY_TOKEN: 'セッション有効時間が切れました。リロードしてから再度お試しください。',
  NOT_FOUND: 'レコードが見つかりませんでした。リロードしてから再度お試しください。',
}

type GraphQLErrorCode = keyof typeof ERROR_MESSAGES

type GraphQLErrorWithCode = GraphQLFormattedError & {
  extensions?: {
    code?: GraphQLErrorCode
  }
}

const DEFAULT_ERROR_MESSAGE = 'リクエストエラーが発生しました。リロードしてから再度お試しください。'

const handleError = (code?: GraphQLErrorCode): void => {
  const message = code ? ERROR_MESSAGES[code] : DEFAULT_ERROR_MESSAGE
  showError(message)
}

interface ErrorResponseExt extends ErrorResponse {
  graphQLErrors?: readonly GraphQLErrorWithCode[]
}

export function createErrorLink(): ApolloLink {
  // NOTE: バッチリクエストを考慮して間引いている
  const debouncedHandleError = debounce(handleError, 500)

  return onError(({ graphQLErrors, response, networkError, operation }: ErrorResponseExt) => {
    if (!graphQLErrors) return

    for (const { extensions } of graphQLErrors) {
      debouncedHandleError(extensions?.code)
    }

    // NOTE: 想定エラーのみのケースではbugsnag通知無し
    if (response && graphQLErrors.every(({ extensions }) => extensions?.code && !!ERROR_MESSAGES[extensions.code])) {
      // SEE: https://www.apollographql.com/docs/react/data/error-handling/#ignoring-errors
      response.errors = undefined
      return
    }

    const errors = [...graphQLErrors, ...(networkError ? [networkError] : [])]
    const errorMessage = errors.map((error) => error.message).join('\n')
    window.bugsnagClient?.notify(new Error(errorMessage), (event) => {
      event.severity = 'info'
      event.context = 'apollo-handled-error'
      event.addMetadata('errors', errors)
      event.addMetadata('query', operation.query)
      event.addMetadata('variables', operation.variables)
    })
  })
}
