<script lang="ts" setup>
import { gql } from '@apollo/client/core'
import { useBrowserLocation } from '@vueuse/core'
import type { Item } from '@/src/components/PageTitleDropdownMenu.vue'
import type { UserForUserMenuFragment } from '@/src/graphql/operations'
import { userMonthlyMessagesPath, userPath, userWorkAnalysisPath } from '@/src/lib/rails-routes'
import { changeParentIdInLocation } from '@/src/lib/urlHelper'

gql`
  fragment userForUserMenu on User {
    id
    name
    avatarPath
  }
`

type MenuName = 'show' | 'analysis' | 'messages'

const props = defineProps<{
  id: string
  name: string
  avatarPath: string
  loading: boolean
  users: readonly UserForUserMenuFragment[]
  onShow: () => void
}>()

const activeMenuName = computed<MenuName>(() => {
  if (window.location.pathname === userPath({ id: props.id })) {
    return 'show'
  }
  if (window.location.pathname.startsWith(userWorkAnalysisPath({ userId: props.id }))) {
    return 'analysis'
  }
  if (window.location.pathname.startsWith(userMonthlyMessagesPath({ userId: props.id }))) {
    return 'messages'
  }

  throw new Error('invalid menu by TheUserMenu.vue')
})

const location = useBrowserLocation()

const items = computed<readonly Item[]>(() =>
  props.users.map((user) => ({
    title: user.name,
    url: changeParentIdInLocation(location.value, user.id, ['date']),
    avatarPath: user.avatarPath,
  })),
)
</script>

<template>
  <div class="hstack tw-space-x-3">
    <PageTitleDropdownMenu :loading="loading" :items="items" tooltip="他の人を見る" @show="onShow">
      <div class="hstack tw-space-x-2">
        <UserAvatar :src="avatarPath" type="small" />
        <span>{{ name }}</span>
      </div>
    </PageTitleDropdownMenu>
    <div class="buttons has-addons is-hidden-mobile">
      <BuButton
        icon="bi:chat-text"
        tag="a"
        size="is-small"
        :href="userMonthlyMessagesPath({ userId: id })"
        :class="{ 'is-active': activeMenuName === 'messages' }"
      >
        ひとこと履歴
      </BuButton>
      <BuButton
        icon="bi:graph-up"
        tag="a"
        size="is-small"
        :href="userWorkAnalysisPath({ userId: id })"
        :class="{ 'is-active': activeMenuName === 'analysis' }"
      >
        個人分析
      </BuButton>
      <BuButton
        icon="bi:list-task"
        tag="a"
        size="is-small"
        :href="userPath({ id })"
        :class="{ 'is-active': activeMenuName === 'show' }"
      >
        個人ビュー
      </BuButton>
    </div>
  </div>
</template>
