<template>
  <div class="chat-message tw-group" :data-test-selector="`chat-message-${chatMessage.id}`">
    <div class="chat-message__main tw-gap-2" :class="{ myself: isMyself }">
      <div class="chat-message__usericon">
        <UserAvatar :src="chatMessage.user.avatarPath" type="small" />
      </div>
      <div>
        <div class="chat-message__username mb-1">{{ chatMessage.user.name }}</div>
        <ChatContent ref="chatContent" :chat-message="chatMessage" :on-long-press-message="setShowModal.on" />
      </div>
      <div v-if="!isMobile" class="is-flex group-hover:tw-visible" :class="{ 'tw-invisible': isMyself }">
        <ChatMessageButton
          v-if="isMyself"
          tooltip-content="削除"
          data-test-selector="chat-message-delete-button"
          icon="la--trash"
          @click="handleDelete"
        />
        <ChatMessageButton
          v-else
          tooltip-content="返信"
          data-test-selector="chat-message__reply-button"
          icon="la--reply"
          @click="$emit('reply')"
        />
        <ChatMessageButton
          tooltip-content="いいね"
          data-test-selector="chat-message-like-button"
          icon="la--thumbs-up"
          @click="$emit('like', liked)"
        />
      </div>
      <div class="chat-message__info">
        <DateTimeOrTime class="chat-message__time" :value="chatMessage.createdAt" date-time-format="MM/DD HH:mm" />
      </div>
    </div>
    <div class="chat-message__like-counter" :class="{ myself: isMyself }">
      <LikeCounter :likes="chatMessage.likes" :current-user-id="currentUserId" />
    </div>
    <Teleport to="body">
      <BModal v-model="isShowModal">
        <div class="dropdown-content mx-4" @click="setShowModal.off">
          <div
            class="dropdown-item"
            aria-role="listitem"
            data-test-selector="chat-message-like-button"
            @click="$emit('like', liked)"
          >
            いいね
          </div>
          <div v-if="chatMessage.blob" class="dropdown-item" aria-role="listitem" @click="showImage">画像を拡大</div>
          <div v-if="chatMessage.content" class="dropdown-item" aria-role="listitem" @click="handleCopy">コピー</div>
          <div
            v-if="isMyself"
            aria-role="listitem"
            class="has-text-danger dropdown-item"
            data-test-selector="chat-message-delete-button"
            @click="handleDelete"
          >
            コメントを削除
          </div>
          <div
            v-else
            class="dropdown-item"
            aria-role="listitem"
            data-test-selector="chat-message__reply-button"
            @click="$emit('reply')"
          >
            コメントに返信
          </div>
        </div>
      </BModal>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { gql } from '@apollo/client/core'
import { useClipboard } from '@vueuse/core'
import type { PropType } from 'vue'
import type ChatContent from './ChatContent.vue'
import { useMobileScreen } from '@/src/hooks'
import { useBoolean } from '@/src/hooks/useBoolean'
import type { ChatMessage } from '@/src/hooks/useChat'
import { showSuccess } from '@/src/lib/toast'

gql`
  fragment chatMessageForChatMessage on ChatMessage {
    id
    content
    isImage
    htmlContent
    createdAt
    user {
      id
      ...userBasicInfo
    }
    blob {
      id
      url
      variantUrl(variant: RESIZE_640)
      metadata {
        width
        height
      }
    }
    likes {
      id
      ...likeForLikeCounter
    }
  }
`

export default defineComponent({
  props: {
    chatMessage: {
      type: Object as PropType<ChatMessage>,
      required: true,
    },
    currentUserId: {
      type: String,
      required: true,
    },
  },
  emits: {
    delete: () => true,
    reply: () => true,
    like: (_liked: boolean) => true,
  },
  setup(props, context) {
    const isMobile = useMobileScreen()
    const [isShowModal, setShowModal] = useBoolean(false)
    const chatContent = ref<InstanceType<typeof ChatContent>>()
    const isMyself = computed(() => props.chatMessage.user.id === props.currentUserId)
    const { copy } = useClipboard({ read: false })

    const liked = computed(() => props.chatMessage.likes.some(({ user }) => user.id === props.currentUserId))

    const handleCopy = () => {
      copy(props.chatMessage.content)
      showSuccess('コピーしました。')
    }

    const handleDelete = (): void => {
      if (!window.confirm('コメントを削除します。本当によろしいですか?')) return

      context.emit('delete')
    }

    const showImage = (): void => {
      chatContent.value?.showImage()
    }

    return {
      chatContent,
      handleCopy,
      handleDelete,
      isMobile,
      isMyself,
      isShowModal,
      liked,
      setShowModal,
      showImage,
    }
  },
})
</script>

<style scoped lang="scss">
@use '@/stylesheets/application/resources';

$_usericon-width: 30px;

.chat-message__info {
  align-items: flex-end;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.chat-message__usericon {
  flex-basis: $_usericon-width;
  flex-grow: 0;
  width: $_usericon-width;
}

.chat-message__username {
  font-size: 9px;
}

.chat-message__main {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 0;

  &.myself {
    flex-direction: row-reverse;
    justify-content: flex-end;

    // TODO: deep使わないようにしたい、、
    :deep(.chat-content--text) {
      background: #aae7ff;
      border-radius: 12px 0 12px 12px;
    }

    :deep(.chat-content--image) {
      text-align: right;
    }

    .chat-message__usericon {
      display: none;
    }

    .chat-message__info {
      justify-content: flex-start;
    }

    .chat-message__username {
      display: none;
    }
  }
}

.chat-message__content {
  flex-basis: 100%;
  flex-grow: 1;
}

.chat-message__talkdata {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.chat-message__time {
  color: #ccc;
  font-size: 9px;
}

.chat-message__like-counter {
  margin-left: $_usericon-width;

  &.myself {
    display: flex;
    justify-content: flex-end;
    margin-left: 0;
  }
}

@include resources.mobile {
  .chat-message__main {
    padding: 5px 10px 0;
  }

  // TODO: deep使わないようにしたい、、
  .chat-message__main.myself :deep(.chat-content--text) {
    padding: 5px 10px;
  }
}
</style>
