export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ChatSource: ['DoneWork', 'FinishMessage', 'StartMessage'],
    CreateChatMessageResult: ['ChatMessage', 'ValidationErrors'],
    CreateDoneWorkResult: ['DoneWork', 'ValidationErrors'],
    CreatePlannedWorkResult: ['PlannedWork', 'ValidationErrors'],
    DeleteDoneWorkResult: ['User', 'ValidationErrors'],
    FinishPlanResult: ['Plan', 'ValidationErrors'],
    Node: [
      'Blob',
      'Chat',
      'ChatMessage',
      'ChatReadMark',
      'Company',
      'CompanyLargeCategory',
      'DoneWork',
      'FinishMessage',
      'Like',
      'Notification',
      'NotificationRecipient',
      'Plan',
      'PlannedWork',
      'RecurrencePlannedWork',
      'ReleaseNote',
      'StartMessage',
      'TaskTemplate',
      'Team',
      'TeamMember',
      'User',
    ],
    Notifiable: ['ChatMessage', 'Plan'],
    StartPlanResult: ['Plan', 'ValidationErrors'],
    UpdateDoneWorkResult: ['DoneWork', 'ValidationErrors'],
  },
}
export default result
