<script lang="ts">
export type OnClick = (date: Temporal.PlainDate) => void
</script>

<script lang="ts" setup>
import { isHoliday } from '@holiday-jp/holiday_jp'
import { Temporal } from '@js-temporal/polyfill'
import { range } from 'lodash'
import {
  dayOfWeekStartingOnSunday,
  getPlainDatesInMonth,
  isToday,
  plainDateToLegacyDate,
} from '@/src/lib/dateTimeUtils'
import { assertIsDefined } from '@/src/lib/typeUtils'

const DAY_OF_THE_WEEK_SUNDAY = 7 as const
const DAY_OF_THE_WEEK_SATURDAY = 6 as const

const formatDates = (dates: readonly string[]): readonly string[] => {
  return dates.map((date) => Temporal.PlainDate.from(date).toString())
}

const dayClasses = (date?: Temporal.PlainDate): { [key: string]: boolean } => {
  if (!date) return {}

  return {
    today: isToday(date),
    sunday: date.dayOfWeek === DAY_OF_THE_WEEK_SUNDAY,
    saturday: date.dayOfWeek === DAY_OF_THE_WEEK_SATURDAY,
    holiday: isHoliday(plainDateToLegacyDate(date)),
  }
}

type Day = {
  name: string
  date?: Temporal.PlainDate
}

type OnChangeMonth = (yearMonth: Temporal.PlainYearMonth) => void | Promise<void>

type State = {
  yearMonth: Temporal.PlainYearMonth
}

const props = defineProps<{
  onClick: OnClick
  onChangeMonth: OnChangeMonth
  morningDates: readonly string[]
  eveningDates: readonly string[]
  startDate: string
}>()

const state = reactive<State>({
  yearMonth: Temporal.PlainYearMonth.from(props.startDate),
})
const title = computed(() => `${state.yearMonth.year}年${state.yearMonth.month}月`)

const weekNames = ['日', '月', '火', '水', '木', '金', '土']

const month = computed<Day[][]>(() => {
  const dates = getPlainDatesInMonth(state.yearMonth)
  const firstDate = dates[0]
  const endDate = dates.at(-1)
  assertIsDefined(endDate)

  const calendarDays: readonly Day[] = [
    ...range(dayOfWeekStartingOnSunday(firstDate)).map(() => ({
      name: '',
    })),
    ...dates.map((value) => ({
      name: value.day.toString(),
      date: value,
    })),
    ...range(weekNames.length - 1 - dayOfWeekStartingOnSunday(endDate)).map(() => ({
      name: '',
    })),
  ]

  const result: Day[][] = []
  for (let day = 0, week = 7; day < calendarDays.length; day += week) {
    result.push(calendarDays.slice(day, day + week))
  }
  return result
})

const formattedMorningDates = computed<readonly string[]>(() => formatDates(props.morningDates))
const formattedEveningDates = computed<readonly string[]>(() => formatDates(props.eveningDates))

const handleChangeMonth = (durationMonth: number): void => {
  state.yearMonth = state.yearMonth.add({ months: durationMonth })
  props.onChangeMonth(state.yearMonth)
}

const hasMorningDate = (date: Temporal.PlainDate): boolean => {
  return formattedMorningDates.value.includes(date.toString())
}

const hasEveningDate = (date: Temporal.PlainDate): boolean => {
  return formattedEveningDates.value.includes(date.toString())
}

const handleClickDate = (date?: Temporal.PlainDate): void => {
  if (!date) return

  props.onClick(date)
}
</script>

<template>
  <div class="calendar-box">
    <div class="header">
      <BuButton
        type="is-ghost"
        size="is-small"
        icon="bi:chevron-left"
        class="has-text-dark"
        @click="handleChangeMonth(-1)"
      />
      <div class="has-text-grey">{{ title }}</div>
      <BuButton
        type="is-ghost"
        size="is-small"
        icon="bi:chevron-right"
        class="has-text-dark"
        @click="handleChangeMonth(1)"
      />
    </div>
    <table class="table">
      <thead class="head">
        <tr class="week">
          <th v-for="(week, key) in weekNames" :key="key" class="day" v-text="week" />
        </tr>
      </thead>
      <tbody class="body">
        <tr v-for="(week, key) in month" :key="key" class="week">
          <td
            v-for="(day, _key) in week"
            :key="_key"
            class="day"
            :class="dayClasses(day.date)"
            @click="handleClickDate(day.date)"
          >
            <span class="day-num" v-text="day.name" />
            <div class="recorded-mark">
              <div
                :class="{
                  'is-invisible': !day.date || !hasMorningDate(day.date),
                }"
                class="morning"
              />
              <div
                :class="{
                  'is-invisible': !day.date || !hasEveningDate(day.date),
                }"
                class="evening"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
@use '@/stylesheets/application/resources';

$_alert-color: #e24a3f;
$_main-color: #ccc;
$_morning-color: #f5a623;
$_evening-color: #4a90e2;

.calendar-box {
  box-shadow: 0 2px 4px $_main-color;
}

.header {
  align-items: center;
  border: 1px solid $_main-color;
  border-bottom: none;
  border-radius: 3px 3px 0 0;
  display: flex;
  justify-content: space-between;
}

.table {
  border-collapse: collapse;

  @include resources.mobile {
    width: 100%;
  }
}

.week {
  font-size: 12px;
  font-weight: bold;
}

.day {
  border: 1px solid $_main-color;
  color: #888;
  line-height: 1;
  padding: 8px 16px 4px;
  text-align: center;
  vertical-align: middle;

  @include resources.mobile {
    padding: 12px 10px;
  }

  &:hover:not(:empty) {
    background-color: #efefef;
    cursor: pointer;
  }

  &.sunday,
  &.holiday {
    background-color: #fcf0f0;
    color: #b01f24;
  }

  &.saturday {
    background-color: #e9f2fb;
    color: #3366a2;
  }

  &.today {
    background: #ecf6ec;
    color: #000;
    font-weight: bold;
  }
}

.recorded-mark {
  display: flex;
  justify-content: center;

  > div {
    border-radius: 50%;
    height: 4px;
    margin: 4px 3px 0;
    width: 4px;
  }

  .morning {
    background-color: $_morning-color;
  }

  .evening {
    background-color: $_evening-color;
  }
}
</style>
