<script lang="ts" setup>
const ICONS = {
  sun: {
    iconClass: 'bi--sun-fill',
    colorClass: 'tw-text-orange-500',
  },
  moon: {
    iconClass: 'bi--moon-stars-fill',
    colorClass: 'tw-text-blue-800',
  },
  fire: {
    iconClass: 'fa-solid--fire',
    colorClass: 'tw-text-red-500',
  },
  up: {
    iconClass: 'bi--graph-up',
    colorClass: 'tw-text-red-500',
  },
  down: {
    iconClass: 'bi--graph-down',
    colorClass: 'tw-text-teal-300',
  },
  stopwatch: {
    iconClass: 'fa-solid--stopwatch',
    colorClass: 'tw-text-blue-400',
  },
  laptopHouse: {
    iconClass: 'fa-solid--laptop-house',
    colorClass: 'tw-text-orange-600',
  },
}

type Icons = keyof typeof ICONS

const props = defineProps<{
  icon: Icons
}>()

const iconInfo = computed(() => ICONS[props.icon])
</script>

<template>
  <span class="tw-iconify" :class="[iconInfo.colorClass, `tw-${iconInfo.iconClass}`]" />
</template>
