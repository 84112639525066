<template>
  <div class="the-drawer">
    <div class="btn-menu" data-test-selector="drawer-btn" @click="setIsOpen.on">
      <span class="tw-iconify tw-la--bars" />
    </div>
    <transition name="slide">
      <div v-if="isOpen" class="drawer">
        <div class="drawer-inner">
          <div class="drawer-close" @click="setIsOpen.off">
            <a>
              <span class="tw-iconify tw-la--times mr-1 tw-text-2xl" />
              <span>とじる</span>
            </a>
          </div>
          <div class="drawer-profile">
            <UserAvatar :src="currentUser.avatarPath" class="face-icon" />
            <div class="profile-data">
              <div class="name">{{ currentUser.name }}</div>
              <div v-if="company" class="corp-name">{{ company.name }}</div>
              <a class="profile-account is-hidden-mobile" :href="railsRoutes.editMyUserPath()"> アカウント設定 </a>
            </div>
          </div>

          <div class="drawer-main-links">
            <ul>
              <li v-if="!(todayPlan && todayPlan.finished)">
                <a :href="railsRoutes.editScheduleTimelinePath()" class="drawer-main-links-item">
                  <span class="tw-iconify fixed-width-icon" :class="`tw-${editScheduleIcon}`" />
                  {{ editScheduleText }}
                </a>
              </li>
              <li class="is-hidden-mobile">
                <a :href="railsRoutes.userWorkAnalysisPath({ userId: currentUser.id })" class="drawer-main-links-item">
                  <span class="tw-iconify tw-bi--graph-up fixed-width-icon" />
                  過去30日間の分析を確認
                </a>
              </li>
            </ul>
          </div>

          <div class="drawer-navs">
            <div class="drawer-nav">
              <div class="tw-text-xs mb-1 has-text-grey-light">所属チーム</div>
              <ul>
                <li v-for="team in sortedTeams" :key="team.id">
                  <a :href="railsRoutes.teamPath({ id: team.id })" v-text="team.name" />
                </li>
              </ul>
            </div>
            <div class="drawer-nav drawer-nav-settings">
              <div class="tw-text-xs mb-1 has-text-grey-light">法人設定</div>
              <ul>
                <template v-if="currentUser.companyAdmin">
                  <li class="is-hidden-mobile">
                    <a :href="railsRoutes.companiesTeamsPath()" class="drawer-nav-link">チーム管理</a>
                  </li>
                  <li class="is-hidden-mobile">
                    <a :href="railsRoutes.companiesUsersPath()" class="drawer-nav-link">ユーザー管理</a>
                  </li>
                  <li class="is-hidden-mobile">
                    <a :href="railsRoutes.editCompaniesSettingPath()" class="drawer-nav-link"> 会社情報 </a>
                  </li>
                  <li class="is-hidden-mobile">
                    <a :href="railsRoutes.companiesWorkAnalysisPath()" class="drawer-nav-link"> 会社分析 </a>
                  </li>
                  <li class="is-hidden-mobile">
                    <a :href="railsRoutes.companiesCompanyServicePlanPath()" class="drawer-nav-link"> 契約情報 </a>
                  </li>
                </template>
                <li>
                  <a :href="railsRoutes.destroyUserSessionPath()" data-method="delete" class="drawer-nav-link">
                    ログアウト
                  </a>
                </li>
              </ul>
            </div>

            <div class="drawer-nav drawer-nav-settings">
              <div class="tw-text-xs mb-1 has-text-grey-light">その他</div>
              <ul>
                <li>
                  <a
                    href="https://wlb-tool-support.notion.site/wlb-tool-support/9b925eea6c4b444fb4e2f6d76cfdf5a5"
                    target="_blank"
                    class="drawer-nav-link"
                  >
                    お知らせ・マニュアル
                  </a>
                </li>
              </ul>
            </div>

            <ul class="the-drawer__footer pt-2 tw-space-x-3">
              <li class="is-inline is-size-8">
                <a :href="railsRoutes.staticPagePath({ id: 'rule' })" class="has-text-grey-light"> 利用規約 </a>
              </li>
              <li class="is-inline is-size-8">
                <a :href="railsRoutes.staticPagePath({ id: 'privacy' })" class="has-text-grey-light">
                  プライバシーポリシー
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="isOpen" class="drawer-bg" @click="setIsOpen.off" />
    </transition>
  </div>
</template>

<script lang="ts">
import { useLazyQuery } from '@vue/apollo-composable'
import type { CurrentUserForDrawerQuery } from '@/src/graphql/operations'
import { CurrentUserForDrawerDocument } from '@/src/graphql/operations'
import { useCurrentUser } from '@/src/hooks'
import { useBoolean } from '@/src/hooks/useBoolean'
import { useSorted } from '@/src/hooks/useSorted'
import { currentPlainJSTDate } from '@/src/lib/dateTimeUtils'
import * as railsRoutes from '@/src/lib/rails-routes'

type Team = CurrentUserForDrawerQuery['currentUser']['teams'][number]
type Plan = CurrentUserForDrawerQuery['currentUser']['dailyPlan']

export default defineComponent({
  setup() {
    const [isOpen, setIsOpen] = useBoolean(false)

    const currentUser = useCurrentUser()

    const { result, load } = useLazyQuery(CurrentUserForDrawerDocument, () => ({
      today: currentPlainJSTDate().toString(),
    }))

    watch(isOpen, () => {
      load()
    })

    const company = computed(() => result?.value?.currentUser.company)
    const teams = computed<readonly Team[]>(() => result?.value?.currentUser.teams ?? [])
    const todayPlan = computed<Plan | undefined>(() => result?.value?.currentUser.dailyPlan)

    const sortedTeams = useSorted(teams, (a, b) => {
      if (a.id === currentUser.mainTeamId) return -1
      if (b.id === currentUser.mainTeamId) return 1
      return a.name.localeCompare(b.name)
    })

    const isStarted = computed(() => !!todayPlan.value)
    const editScheduleText = computed(() => (isStarted.value ? '今日の実績を登録' : '今日の予定を登録'))
    const editScheduleIcon = computed(() => (isStarted.value ? 'bi--moon-stars-fill' : 'bi--sun-fill'))

    return {
      isOpen,
      setIsOpen,
      editScheduleIcon,
      company,
      sortedTeams,
      editScheduleText,
      todayPlan,
      currentUser,
      railsRoutes,
    }
  },
})
</script>

<style scoped lang="scss">
// NOTE: デザイナーさん用意のCSSをそのまま利用していて書換面倒なので、、
/* stylelint-disable declaration-property-value-no-unknown */
@use '@/stylesheets/application/resources';
@use 'sass:color';

$_link-color: #fff;

.the-drawer {
  height: 100%;
}

.drawer-close {
  a {
    align-items: center;
    display: flex;
    height: 60px;
    margin-left: 1rem;
  }

  span {
    font-size: 0.75rem;
  }
}

.btn-menu {
  align-items: center;
  background-image: linear-gradient(-135deg, rgb(221, 76, 9), #06177a);
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 26px;
  height: 100%;
  justify-content: center;
  width: 60px;

  @include resources.mobile {
    flex-shrink: 0;
  }

  .tw-iconify {
    transition: 0.2s;
  }

  &:hover {
    .tw-iconify {
      transform: rotate(90deg);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s 0.1s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-300px);
}

.drawer-bg {
  background-color: rgba(#000, 0.8);
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 19;

  &:hover {
    opacity: 0.8;
  }
}

.drawer-nav-link {
  display: inline-block;
  font-size: 0.85rem;
  padding: 0.3rem 0;

  &:hover {
    text-decoration: underline;
  }
}

.drawer {
  color: $_link-color;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 20;

  a {
    color: $_link-color;
    text-decoration: none;
    transition: color 0.1s;

    &:hover {
      color: color.adjust(#fff, $lightness: -15%);
    }
  }
}

.drawer-inner {
  background-image: linear-gradient(-135deg, rgb(221, 76, 9), #06177a);
  box-shadow: 1px 0 3px rgba(#333, 0.7);
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 300px;
  z-index: 2;
}

.drawer-profile {
  align-items: flex-start;
  display: flex;
  padding: 0 1rem 1rem;

  .face-icon {
    margin-right: 10px;
  }

  .name {
    font-size: 1.4em;
  }

  .corp-name {
    font-size: 0.75rem;
    margin-bottom: 2px;
  }

  .profile-account {
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: x-small;
    font-size: xx-small;
    line-height: 1;
    padding: 0.2rem 0.6rem;

    &:hover {
      border: 1px solid color.adjust(#fff, $lightness: -15%);
    }
  }
}

.drawer-main-links {
  border-top: 1px solid rgba(#fff, 0.2);
  margin: 0 1rem;

  .drawer-main-links-item {
    border-bottom: 1px solid rgba(#fff, 0.2);
    display: block;
    font-size: 0.95rem;
    line-height: 1.3;
    padding: 0.6rem 0;
  }
}

.drawer-navs {
  flex-shrink: 4;
  margin-right: 0.5rem;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding-bottom: 2rem;
}

::-webkit-scrollbar {
  position: relative;
  right: 1rem;
  width: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(#fff, 0.3);
  border-radius: 10px;
}

.drawer-nav {
  margin: 1.8rem 1rem;
}

.drawer-nav-settings {
  .drawer-nav-link {
    font-size: 0.75rem;
    padding: 0.2rem 0;
  }
}

.the-drawer__footer {
  border-top: 1px solid rgba(#fff, 0.2);
  margin: 0 1rem;
}
</style>
