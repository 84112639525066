<template>
  <a
    class="notification-item has-text-dark tw-text-xs tw-border-b tw-border-gray-200 hover:tw-bg-gray-100"
    :href="notificationPath({ id: notification.id })"
    :class="{ 'tw-bg-yellow-50': !notificationRecipient.isRead }"
  >
    <div class="notification-item__icon">
      <UserAvatar :src="user.avatarPath" type="middle" />
    </div>
    <component
      :is="contentComponent"
      :notification-key="notification.key"
      :created-at="notificationRecipient.createdAt"
      :notifiable="notification.notifiable"
    />
  </a>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import NotificationItemMentionContent from './NotificationItemMentionContent.vue'
import NotificationItemPlanContent from './NotificationItemPlanContent.vue'
import type { NotificationRecipientsQuery } from '@/src/graphql/operations'
import type { RelayNode } from '@/src/graphql/typeUtil'
import { notificationPath } from '@/src/lib/rails-routes'

type NotificationRecipient = RelayNode<NotificationRecipientsQuery['notificationRecipients']>

export default defineComponent({
  components: {
    NotificationItemMentionContent,
    NotificationItemPlanContent,
  },
  props: {
    notificationRecipient: {
      type: Object as PropType<NotificationRecipient>,
      required: true,
    },
  },
  setup(props) {
    const classes = computed(() => ({
      'notification-item--unread': !props.notificationRecipient.isRead,
    }))
    const notification = computed(() => props.notificationRecipient.notification)
    const user = computed(() => notification.value.notifiable.user)
    const contentComponent = computed(() => {
      switch (notification.value.notifiable.__typename) {
        case 'ChatMessage': {
          return 'NotificationItemMentionContent'
        }
        case 'Plan': {
          return 'NotificationItemPlanContent'
        }
        default: {
          throw new Error('Invalid notifiable')
        }
      }
    })

    return { classes, contentComponent, notification, user, notificationPath }
  },
})
</script>

<style lang="scss" scoped>
@use '@/stylesheets/application/resources';

.notification-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  padding: 8px 0;

  @include resources.mobile {
    width: inherit;
  }
}

.notification-item__icon {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 60px;
}
</style>
