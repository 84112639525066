<script lang="ts" setup>
import { gql } from '@apollo/client/core'
import { useBrowserLocation } from '@vueuse/core'
import type { Item } from '@/src/components/PageTitleDropdownMenu.vue'
import type { TeamForTeamDropdownMenuFragment } from '@/src/graphql/operations'
import {
  editTeamPath,
  teamPath,
  teamTaskTemplatesPath,
  teamTeamMembersPath,
  teamWorkAnalysisPath,
} from '@/src/lib/rails-routes'
import { changeParentIdInLocation } from '@/src/lib/urlHelper'
import type { ClassNames } from '@/src/types/utils'

gql`
  fragment teamForTeamDropdownMenu on Team {
    id
    name
  }
`

const props = defineProps<{
  id: string
  name: string
  loading: boolean
  teams: readonly TeamForTeamDropdownMenuFragment[]
  onShowTeamDropdownMenu: () => void
}>()

const teamWorkAnalysisPathWithParams = computed(() => teamWorkAnalysisPath({ teamId: props.id }))

const activeMenuName = computed(() => {
  if (window.location.pathname === teamPath({ id: props.id })) {
    return 'teamview'
  }
  if (window.location.pathname.startsWith(teamWorkAnalysisPathWithParams.value)) {
    return 'analysis'
  }
  return 'other'
})

const location = useBrowserLocation()

const items = computed<readonly Item[]>(() =>
  props.teams.map((team) => ({
    title: team.name,
    url: changeParentIdInLocation(location.value, team.id, ['date']),
  })),
)

const buttonClasses = (name: string): ClassNames => [
  'button',
  'is-small',
  { 'is-active': activeMenuName.value === name },
]
</script>

<template>
  <div class="hstack tw-space-x-3">
    <PageTitleDropdownMenu :loading="loading" :items="items" tooltip="他のチームを見る" @show="onShowTeamDropdownMenu">
      {{ name }}
    </PageTitleDropdownMenu>
    <div class="buttons has-addons is-hidden-mobile">
      <a :class="buttonClasses('teamview')" :href="teamPath({ id })">
        <span class="icon">
          <span class="tw-iconify tw-bi--list-task" />
        </span>
        <span>チームビュー</span>
      </a>
      <a :class="buttonClasses('analysis')" :href="teamWorkAnalysisPath({ teamId: id })">
        <span class="icon">
          <span class="tw-iconify tw-bi--graph-up" />
        </span>
        <span>分析</span>
      </a>
      <VDropdown>
        <button :class="buttonClasses('other')">
          <span class="icon">
            <span class="tw-iconify tw-bi--three-dots-vertical" />
          </span>
          <span>設定</span>
        </button>
        <template #popper>
          <a class="dropdown-item" :href="teamTaskTemplatesPath({ teamId: id })">行動小項目</a>
          <a class="dropdown-item" :href="teamTeamMembersPath({ teamId: id })">メンバー管理</a>
          <a class="dropdown-item" :href="editTeamPath({ id })">チーム情報</a>
        </template>
      </VDropdown>
    </div>
  </div>
</template>
