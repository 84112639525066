<template>
  <div class="icon-with-badge">
    <slot />
    <span
      v-if="count > 0"
      class="tag is-rounded is-danger px-2 icon-with-badge__badge"
      data-test-selector="icon-with-badge__badge"
    >
      {{ count }}
    </span>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.icon-with-badge {
  font-size: 26px;
  line-height: 1;
  position: relative;
}

.icon-with-badge__badge {
  font-size: 12px;
  height: 1.5em;
  left: 100%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
}
</style>
